import { Injectable } from '@angular/core';
import { ICurrencies, IPaymentType } from '../models/ISales';

@Injectable({
  providedIn: 'root', // This makes the service available app-wide
})
export class MasterService {
  private currency: Array<ICurrencies>;
  private paymentTypes: Array<IPaymentType>;

  setCurrency(value: Array<ICurrencies>): void {
    this.currency = value;
  }

  getCurrency(): Array<ICurrencies> {
    return this.currency;
  }

  setPaymentTypes(value: Array<IPaymentType>): void {
    this.paymentTypes = value;
  }

  getPaymentTypes(): Array<IPaymentType> {
    return this.paymentTypes;
  }
}
