import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserData } from '../providers/user-data';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userData: UserData,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return this.userData.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        // If the user is not logged in, redirect to the login page
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    });
  }
}
