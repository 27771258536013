import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
    public url = new BehaviorSubject<string>('/home');
    currentUrl = this.url.asObservable();

    changeUrl(url: string){
      console.log(url);
      
        this.url.next(url);
    }
}
