<ion-app [class.ion-palette-dark]="dark">
  <ion-split-pane content-id="main-content" when="(min-width: 1200px)" >
    <!-- The Menu -->
    <ion-menu content-id="main-content" *ngIf="currentUrl !== '/login'">
      <ion-header [translucent]="true">
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list lines="none">
          <ng-container *ngIf="loggedIn">
            <!-- Parent item without children -->
            <ng-container *ngFor="let p of appPages; let i = index">
              <ng-container *ngIf="!p.child">
                <ion-menu-toggle autoHide="false">
                  <ion-item
                    [routerLink]="p.url"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false">
                    <ion-icon
                      slot="start"
                      [name]="p.icon + '-outline'"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>

              <!-- Parent item with children (sub-menu) -->
              <ng-container *ngIf="p.child">
                <ion-item
                  detail="false"
                  lines="none"
                  (click)="toggleSubMenu(i)"
                  class="menu-item">
                  <ion-icon
                    slot="start"
                    [name]="p.icon + '-outline'"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                  <ion-icon
                    slot="end"
                    [name]="
                      expandedIndex === i
                        ? 'chevron-up-outline'
                        : 'chevron-down-outline'
                    "></ion-icon>
                </ion-item>

                <!-- Sub-menu items -->
                <div *ngIf="expandedIndex === i">
                  <ion-menu-toggle
                    autoHide="false"
                    *ngFor="let child of p.child">
                    <ion-item
                      class="sub-menu-item"
                      [routerLink]="child.url"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false">
                      <ion-label>{{ child.title }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Login Option (when not logged in) -->
          <ion-menu-toggle *ngIf="!loggedIn" autoHide="false">
            <ion-item
              routerLink="/login"
              routerLinkActive="active"
              routerDirection="root"
              detail="false">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label> Login </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <!-- Main Content -->
    <ion-content id="main-content">
      <ion-header  *ngIf="currentUrl !== '/login'">
        <ion-toolbar>
          <ion-row>
            <ion-col size="6" class="desktop-hidden">
              <ion-buttons>
                <ion-menu-button></ion-menu-button>
              </ion-buttons>
            </ion-col>
            <ion-col size="6" size-md="4" class="align-center">
              <ion-buttons>
                <ion-menu-button></ion-menu-button>
              </ion-buttons>
              <ion-title>{{greeting}}, <span class="logged_in_user">{{ userName
                  }}</span> </ion-title>
            </ion-col>
            <ion-col size="0" size-md="4" class="align-center ion-text-center">
              <ion-title>{{clock}} </ion-title>
            </ion-col>
            <ion-col size="6" size-md="4"
              class="ion-text-end user_notification ion-padding-horizontal">
              <ion-buttons>
                <ion-label class="principal-portal-label">Principal
                  Portal</ion-label>

                <!-- Notification Bell Button -->
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="notificationMenu"
                  aria-label="Notification Menu"
                  *ngIf="currentUrl !== '/login'"
                  class="notification-bell">
                  <mat-icon matBadge="4"
                    matBadgeClass="red-badge">notifications</mat-icon>
                </button>
                <!-- Notification Menu -->
                <mat-menu #notificationMenu="matMenu">
                  <button (click)="navigateToNotification()" mat-menu-item>
                    <mat-icon>payment</mat-icon>
                    <div>
                      <div>Payment received</div>
                      <div class="notificationText">1000/- received from
                        Somya</div>
                    </div>
                  </button>
                  <button mat-menu-item>
                    <mat-icon>payment</mat-icon>
                    <div>
                      <div>Payment received</div>
                      <div class="notificationText">1000/- received from
                        Somya</div>
                    </div>
                  </button>
                </mat-menu>

                <!-- Profile Icon Button -->
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="profileMenu"
                  aria-label="Profile Menu"
                  *ngIf="currentUrl !== '/login'">
                  <ion-icon slot="icon-only"
                    name="person-circle-outline"></ion-icon>
                </button>
                <!-- Profile Menu -->
                <mat-menu #profileMenu="matMenu">
                  <button mat-menu-item (click)="logoutConfirmation()">
                    <mat-icon>logout</mat-icon>
                    Log Out
                  </button>
                </mat-menu>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
</ion-app>
