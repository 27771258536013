import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ApiInterceptor } from './providers/api.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MasterService } from './providers/master.service';
import { ApiService } from './providers/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';

export function initializeAppFactory(
  http: HttpClient,
  masterService: MasterService,
) {
  return (): Promise<void> => {
    const baseUrl = environment.baseUrl;
    return http
      .get<any>(`${baseUrl}/Master/collections`)
      .toPromise()
      .then(
        (response) => {
          response.map((item) => {
            switch (item.name) {
              case 'currencies':
                masterService.setCurrency(item.list);
                break;
              case 'paymentTypes':
                masterService.setPaymentTypes(item.list);
                break;
            }
          });
        },
        (error) => {
          console.error('Failed to load currency:', error);
        },
      );
  };
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory, // Call the function on app initialization
      deps: [HttpClient, MasterService], // Inject HttpClient and MasterService
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
