import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { checkTutorialGuard } from './providers/check-tutorial.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'appointments',
    loadChildren: () =>
      import('./pages/appointments/appointments.module').then(
        (m) => m.AppointmentsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'organisations',
    loadChildren: () =>
      import('./pages/organisations/organisations.module').then(
        (m) => m.OrganisationsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./pages/sales/sales.module').then((m) => m.SalesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsModule,
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "account",
  //   loadChildren: () =>
  //     import("./pages/account/account.module").then((m) => m.AccountModule),
  // },
  // {
  //   path: "support",
  //   loadChildren: () =>
  //     import("./pages/support/support.module").then((m) => m.SupportModule),
  // },

  // {
  //   path: "signup",
  //   loadChildren: () =>
  //     import("./pages/signup/signup.module").then((m) => m.SignUpModule),
  // },
  // {
  //   path: "app",
  //   loadChildren: () =>
  //     import("./pages/tabs-page/tabs-page.module").then((m) => m.TabsModule),
  // },
  // {
  //   path: "tutorial",
  //   loadChildren: () =>
  //     import("./pages/tutorial/tutorial.module").then((m) => m.TutorialModule),
  //   canMatch: [checkTutorialGuard],
  // },
  // {
  //   path: "dashboard",
  //   loadChildren: () =>
  //     import("./pages/dashboard/dashboard.module").then(
  //       (m) => m.DashboardPageModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
