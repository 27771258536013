import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, Subject, from } from 'rxjs';
import {
  catchError,
  delay,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { UserData } from './user-data';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  constructor(
    private userData: UserData,
    private storage: Storage,
    private router: Router,
    private spinnerService: SpinnerService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const skipLoading = request.headers.has('X-Skip-Loading');
    if (!skipLoading) {
      this.spinnerService.showSpinner(); // Show the loading spinner
    }
    return this.addAuthHeader(request).pipe(
      switchMap((authReq) => {
        return next.handle(authReq).pipe(
          catchError((error: HttpErrorResponse) => {
            return this.handleResponseError(error, request, next);
          }),
          finalize(() => {
            if (!skipLoading) {
              this.spinnerService.hideSpinner();
            }
          }),
        ); // Pass the modified request to the next handler
      }),
    );
  }

  addAuthHeader(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    try {
      return from(this.storage.get('token')).pipe(
        switchMap((token) => {
          // If a token exists, clone the request and set the Authorization header
          if (token) {
            const clonedRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`, // Add the token as Bearer token
              },
            });
            return from([clonedRequest]); // Return the modified request
          }
          // If no token, return the original request
          return from([request]);
        }),
      );
    } catch (err) {
      return from([request]);
    }

    // return from([request]);
  }

  handleResponseError(error: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler) {
    if (error.status === 401 || error.status === 403) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;

        return this.refreshToken().pipe(
          switchMap((newToken) => {
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next("");
            return this.addAuthHeader(request).pipe(
              switchMap((newReq) => next.handle(newReq))
            );
          }),
          catchError((refreshError) => {
            this.refreshTokenInProgress = false;
            this.userData.logout();
            this.router.navigateByUrl('/login');
            return throwError(refreshError);
          })
        );
      } else {
        return this.tokenRefreshed$.pipe(
          switchMap(() => this.addAuthHeader(request).pipe(
            switchMap((newReq) => next.handle(newReq))
          ))
        );
      }
    } else if (error.status === 500 || error.status === 503) {
      // Handle server errors
    }
    return throwError(error);
  }

  refreshToken(): Observable<string> {
    return from(this.storage.get('refresh_token')).pipe(
      switchMap((refreshToken) => {
        if (!refreshToken) {
          throw new Error('No refresh token found');
        }
        return this.userData.refreshToken(refreshToken).pipe(
          tap((newToken) => {
            this.storage.set('token', newToken); // Save the new token
          }),
          map((response:any) => response.token) // Return the new token
        );
      })
    );
  }
}
