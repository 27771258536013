<ion-app [class.ion-palette-dark]="dark">
  <ion-split-pane content-id="main-content" when="(min-width: 1200px)">
    <!-- The Menu -->
    <ion-menu content-id="main-content">
      <ion-header [translucent]="true">
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list lines="none">
          <ng-container *ngIf="loggedIn">
            <!-- Parent item without children -->
            <ng-container *ngFor="let p of appPages; let i = index">
              <ng-container *ngIf="!p.child">
                <ion-menu-toggle autoHide="false">
                  <ion-item
                    [routerLink]="p.url"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                  >
                    <ion-icon
                      slot="start"
                      [name]="p.icon + '-outline'"
                    ></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ng-container>

              <!-- Parent item with children (sub-menu) -->
              <ng-container *ngIf="p.child">
                <ion-item
                  detail="false"
                  lines="none"
                  (click)="toggleSubMenu(i)"
                  class="menu-item"
                >
                  <ion-icon
                    slot="start"
                    [name]="p.icon + '-outline'"
                  ></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                  <ion-icon
                    slot="end"
                    [name]="
                      expandedIndex === i
                        ? 'chevron-up-outline'
                        : 'chevron-down-outline'
                    "
                  ></ion-icon>
                </ion-item>

                <!-- Sub-menu items -->
                <div *ngIf="expandedIndex === i">
                  <ion-menu-toggle
                    autoHide="false"
                    *ngFor="let child of p.child"
                  >
                    <ion-item
                      class="sub-menu-item"
                      [routerLink]="child.url"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                    >
                      <ion-label>{{ child.title }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </div>
              </ng-container>
            </ng-container>

            <!-- Logout Option -->
            <ion-menu-toggle autoHide="false">
              <ion-item button (click)="logoutConfirmation()" detail="false">
                <ion-icon slot="start" name="log-out"></ion-icon>
                <ion-label> Logout </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-container>

          <!-- Login Option (when not logged in) -->
          <ion-menu-toggle *ngIf="!loggedIn" autoHide="false">
            <ion-item
              routerLink="/login"
              routerLinkActive="active"
              routerDirection="root"
              detail="false"
            >
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label> Login </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <!-- Main Content -->
    <ion-content id="main-content">
      <ion-header>
        <ion-toolbar>
          <!-- Menu Button (Only Visible on Mobile) -->
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>Good Morning, {{ userName }} </ion-title>
          <ion-buttons slot="end">
            <ion-label class="principal-portal-label" style="margin-right: 10px"
              >Principal Portal</ion-label
            >
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon matBadge="4" matBadgeClass="red-badge"
                >notifications</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="navigateToNotification()" mat-menu-item>
                <mat-icon>payment</mat-icon>
                <div>
                  <div>Payment recieved</div>
                  <div class="notificationText">1000/- recieved from somya</div>
                </div>
              </button>
              <button mat-menu-item>
                <mat-icon>payment</mat-icon>
                <div>
                  <div>Payment recieved</div>
                  <div class="notificationText">1000/- recieved from somya</div>
                </div>
              </button>
            </mat-menu>
            <ion-button>
              <ion-icon
                slot="icon-only"
                name="person-circle-outline"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
</ion-app>
