export class Constants {
  public static API_PATH: any = {
    // Other APIs
    login: 'Auth/login',
    countryList: 'Country/list',
    stateList: 'State/GetStates/countryId',

    // Organizations
    orgCount: 'Organizations/GetActiveOganizationCount',
    getOrganizationData: 'Organizations/GetOrganizationData/orgId',

    // Razor
    razorpayPlans: 'Razor/plans',
    createSubscription: 'Razor/createSubscription',
    oneTimePaymentplans: 'Razor/oneTimePaymentplans',
    oneTimePaymentLink: 'Razor/oneTimePaymentLink',
    
    // Master
    paymentModes: 'Master/paymentModes',
    entityInformation: 'Master/entityInformation',
    currencies: 'Master/currencies',
    collections: 'Master/collections',

    // LicenseType
    licenses: `LicenseType/list`,
    licenseTerm: `LicenseType/licenseTerm`,
    licenseFeatures: 'LicenseType/licenseFeatures',

    // PrincipalOrganizations
    getOrganizations: 'PrincipalOrganizations/GetPrincipalPortalOrganizations',
    getAllOrganizations: 'PrincipalOrganizations/getAllOrganizations',
    addOrganization: 'PrincipalOrganizations/CreateOrganization',
    getServiceCode: 'PrincipalOrganizations/getServiceCode',
    getServiceCodes: 'PrincipalOrganizations/getServiceCodes',
    getOrganization: 'PrincipalOrganizations/getOrganization',
    updateOrganization: 'PrincipalOrganizations/updateOrganization',
    setOrganisationStatus: 'PrincipalOrganizations/activeInactiveOrganization',

    // Invoice
    calculateInvoice: 'Invoice/calculateInvoice',
    createInvoice: 'Invoice/createInvoice',
    updateInvoice: 'Invoice/updateInvoice',
    cancelInvoice: 'Invoice/cancelInvoice',
    invoicesList: 'Invoice/List',
    invoiceDetail: 'Invoice',
    setLicenseDetails: 'Invoice/setInvoiceLicenseActivationDetails',
    DownloadInvoice: 'Invoice/DownloadInvoiceBytes',
    GetInvoicesByOrgId: 'Invoice/GetInvoicesByOrganizationId',
    publishInvoice: 'Invoice/publishInvoice',
    
    // Payment
    addPayment: 'Payment/addPayment',
    paymentList: 'Payment/list',
    DownloadReceipt: 'Payment/DownloadReceiptBytes',
    paymentDetail: 'Payment',
    getPaymentByInvoiceId: 'Payment/GetPaymentByInvoiceId',
    GetPaymentsWithoutInvoice: 'Payment/GetPaymentsWithoutInvoice',
    updatePaymentWithInvoiceNumber: 'Payment/updatePaymentWithInvoiceNumber',
  };
}
