import { Injectable } from '@angular/core';
import { ApiHandlerService } from './api-handler.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private apiHandle: ApiHandlerService) {}

  getData(apiName: string, params, options?): Observable<any> {
    return this.apiHandle
      .callService(apiName, 'GET', params, options)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .pipe(catchError((e: any) => throwError(this.errorHandler(e))));
  }

  deleteData(apiName: string, params, options?): Observable<any> {
    return this.apiHandle
      .callService(apiName, 'DELETE', params, options)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .pipe(catchError((e: any) => throwError(this.errorHandler(e))));
  }

  postData(apiName: string, params, options?): Observable<any> {
    return this.apiHandle
      .callService(apiName, 'POST', params, options)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .pipe(catchError((e: any) => throwError(e)));
  }

  putData(apiName: string, params, options?): Observable<any> {
    return this.apiHandle
      .callService(apiName, 'PUT', params, options)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .pipe(catchError((e: any) => throwError(e)));
  }

  uploadFile(apiName: string, params, options?): Observable<any> {
    return this.apiHandle
      .callService(apiName, 'POST', params, options, true)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .pipe(catchError((e: any) => throwError(e)));
  }

  errorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - -${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
