import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class ApiHandlerService {
  constructor(private http: HttpClient) {}

  callService(
    apiName: string,
    method = 'GET',
    params: any = {},
    options: any,
    isUpload?: boolean,
  ) {
    let requestUrl = `${environment.baseUrl}/${this.getAPIPath(apiName)}`;

    if (params.endPointQueryList && params.endPointQueryList.length > 0) {
      params.endPointQueryList.map((query) => {
        requestUrl = `${requestUrl}/${query}`;
      });
    }
    const requestOptions = this.requestOptions(method, params, isUpload);
    Object.assign(requestOptions, options);
    return this.request(method, requestUrl, requestOptions);
  }

  private requestOptions(method: string, params: any, isUpload = false) {
    const configOptions = {};
    configOptions['headers'] = !isUpload
      ? this.getHeaders()
      : this.getUploadHeaders();
    if (method === 'POST' || method === 'PUT') {
      configOptions['body'] = params;
    } else {
      configOptions['params'] = this.getHttpParams(params);
    }
    return configOptions;
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Cache-Control', 'no-cache,no-store,max-age=0,immutable');
    return headers;
  }

  private getUploadHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: '*/*',
    });
    return headers;
  }

  private getHttpParams(params: Object): HttpParams {
    return Object.getOwnPropertyNames(params).reduce(
      (p, key) => (key === 'endPointQueryList' ? p : p.set(key, params[key])),
      new HttpParams(),
    );
  }

  private request(method, url, options) {
    return new Observable((observer: Observer<any>) => {
      return this.http.request(method, url, options).subscribe(
        (response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          switch (error.status) {
            case 403:
              observer.complete();
              break;
            default:
              observer.error(error);
              break;
          }
        },
      );
    });
  }

  private getAPIPath(apiName) {
    return Constants.API_PATH[apiName];
  }
}
