import { MenuService } from './pages/shared/services/menu-service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Storage } from '@ionic/storage-angular';
import { AlertController } from '@ionic/angular';
import { UserData } from './providers/user-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public currentTime: string;
  accountMenuOpen = false;
  userName = 'Guest';
  expandedIndex: number | null = null;

  appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
    },
    {
      title: 'Calender',
      url: '/appointments',
      icon: 'calendar',
    },
    {
      title: 'Organisations',
      icon: 'apps',
      child: [
        {
          title: 'Add Organisation',
          url: '/organisations/add'
        },
        {
          title: 'Organisations',
          url: '/organisations/list',
        },
        // {
        //   title: "Organisation Details",
        //   url: "/organisations/org-details",
        // },
      ],
    },
    {
      title: 'Sales',
      icon: 'cash',
      child: [
        {
          title: 'Create Invoice',
          url: '/sales/create-invoice',
        },
        {
          title: 'View Invoices',
          url: '/sales/invoices',
        },
        {
          title: 'Payment Link',
          url: '/sales/add-payment-link',
        },
        // {
        //   title: "Payment Receipt",
        //   url: "/sales/sales-payment-receipt",
        // },
        {
          title: 'Subscription',
          url: '/sales/subscription',
        },
      ],
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'person-add',
    },
    {
      title: 'Notifications',
      url: '/notifications',
      icon: 'notifications',
    },
    {
      title: 'Settings',
      icon: 'cog',
      child: [
        {
          title: 'Licenses',
          url: '/settings/licenses',
        },
      ],
    },
    // {
    //   title: "Schedule",
    //   url: "/app/tabs/schedule",
    //   icon: "calendar",
    // },
    // {
    //   title: "Speakers",
    //   url: "/app/tabs/speakers",
    //   icon: "people",
    // },
    // {
    //   title: "Map",
    //   url: "/app/tabs/map",
    //   icon: "map",
    // },
    // {
    //   title: "About",
    //   url: "/app/tabs/about",
    //   icon: "information-circle",
    // },
  ];
  loggedIn = false;
  dark = false;
  currentUrl: string = '';

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private menuService: MenuService
  ) {
    this.initializeApp();
    this.updateClockAndGreeting();

    this.menuService.currentUrl.subscribe((url) =>{
      this.currentUrl = url;
    });
    // Update the clock and greeting every second
    setInterval(() => {
      this.updateClockAndGreeting();
    }, 1000);
  }

  clock = "";
  greeting: string = "";

  updateClockAndGreeting() {
    const now = new Date();
    const currentHour = now.getHours();

    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    this.clock = now.toLocaleString(undefined, options);
    // Removing 0 from The Start
    this.clock = this.clock.replace(/^0/, '');

    if (currentHour < 12) {
      this.greeting = "Good Morning";
    } else if (currentHour < 17) {
      this.greeting = "Good Afternoon";
    } else {
      this.greeting = "Good Evening";
    }
  }

  async ngOnInit() {
    await this.storage.create();
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.versionUpdates.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload',
          },
        ],
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        SplashScreen.hide();
      }
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then((loggedIn) => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.userData.getUsername().then((name) => {
        if (name) {
          this.userName = name;
        } else {
          this.userName = 'Guest';
        }
      });
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  async logoutConfirmation() {
    const alert = await this.alertController.create({
      header: 'Confirm',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Okay',
          handler: () => {
            this.logout();
          },
        },
      ],
    });

    await alert.present();
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  toggleSubMenu(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null; // Collapse if already expanded
    } else {
      this.expandedIndex = index; // Expand selected menu
    }
  }

  navigateToNotification() {
    this.router.navigate(['notifications']);
  }
}
