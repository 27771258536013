import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private loading: HTMLIonLoadingElement | null = null;
  private activeRequests = 0;

  constructor(private loadingController: LoadingController) {}

  async showSpinner(message = 'Loading...') {
    this.activeRequests++; // Increment the count of active requests

    // Only show the spinner if it's not already shown (first request)
    if (this.activeRequests === 1) {
      this.loading = await this.loadingController.create({
        message: message,
        spinner: 'crescent', // Customize spinner type as per your preference
        duration: 5000, // Optional: set a timeout
      });

      await this.loading.present();
    }
  }

  // Hide the spinner (when all requests are complete)
  async hideSpinner() {
    this.activeRequests--; // Decrement the count of active requests

    // If no active requests remain, hide the spinner
    if (this.activeRequests <= 0) {
      this.activeRequests = 0; // Reset in case of underflow

      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null; // Reset the loading reference
      }
    }
  }

  // Force reset the spinner and count (if necessary)
  resetSpinner() {
    this.activeRequests = 0;

    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }
}
